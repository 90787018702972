<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <!--<el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i> 删除</el-button>-->
        <el-button size="small" type="primary" @click="clickConfirm">生成材料采购合同</el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="buttonRefresh()">刷新</el-button>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="`mbin_id`"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @getTableData="getMbins"
      >
        <template v-slot:supp_abbr="scope">
          <div class="vd_dis">
            <span>
              {{ scope.row.supp_abbr }}
            </span>
            <el-link type="primary" @click="replaceSupp(scope.row)">更换</el-link>
          </div>
        </template>
      </search-table>
    </el-card>
    <el-dialog title="更换供应商" :visible.sync="dialogTableVisible" width="70%">
      <SuppList @suppChangeId="suppChangeId"></SuppList>
    </el-dialog>
  </div>
</template>

<script>
import { get, getNoCatch, post } from '@api/request';
import { mbinAPI } from '@api/modules/mbin';
import { stffAPI } from '@api/modules/staff';
import { modrAPI } from '@/api/modules/modr';
import { custAPI } from '@api/modules/cust';
import { suppAPI } from '@api/modules/supp';
import pubPagination from '@/components/common/pubPagination';
import SuppList from './Component/SuppList.vue';
import { getDateNoTime } from '@assets/js/dateUtils';
import SearchTable from '@/components/table/SearchTableV2.vue';

export default {
  name: 'MbinList',
  components: {
    SearchTable,
    pubPagination,
    SuppList
  },
  data() {
    return {
      tableProperties: [
        this.$store.state.selection,
        this.$store.state.index,
        { prop: 'scon_cndate', itemType: 'date', label: '申购日期', formatter: val => getDateNoTime(val, true) },
        { prop: 'scon_no', itemType: 'input', label: '销售合同号', sortable: false },
        { prop: 'prod_no', itemType: 'input', label: '我司货号' },
        { prop: 'mtrb_no', itemType: 'input', label: '辅料号', sortable: false, labelWidth: '130px' },
        { prop: 'mtrb_name', itemType: 'input', label: '材料名称', sortable: false, overflowTooltip: true },
        { prop: 'mtrb_spec', itemType: 'input', label: '材料规格', input: false, sortable: false, overflowTooltip: true },
        { prop: 'mtrb_unit', itemType: 'input', label: '数量单位', sortable: false },
        { prop: 'prod_mtrb_peruse', itemType: 'input', label: '单耗比', input: false, sortable: false },
        { prop: 'cust_abbr', itemType: 'input', label: '客户简称', sortable: false },
        { prop: 'scon_mtrb_num', itemType: 'input', label: '实际数量', input: false, sortable: false },
        { prop: 'scon_mtrb_applied', itemType: 'input', label: '已申购数量', input: false, sortable: false },
        { prop: 'scon_mtrb_apply', itemType: 'input', label: '需申购数量', input: false, sortable: false },
        { prop: 'supp_abbr', itemType: 'input', label: '供应商', labelWidth: '120px', sortable: false },
        this.$store.state.stff_name,
        this.$store.state.create_time
      ],
      stateList: [
        { id: 0, label: '未申购/部分申购' },
        { id: 1, label: '已申购' }
      ],
      loadFlag: true,
      custList: [],
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {},
      stffUserList: [],
      suppList: [],
      dialogTableVisible: false,
      sconMtrbIdFlag: 0
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.getMbins();
      this.getStffUser();
      this.getCust();
      this.getSupp();
    },
    getMbins() {
      this.loadFlag = true;
      getNoCatch(mbinAPI.getMbins, this.$refs.multiTable.searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        setTimeout(() => {
          this.loadFlag = false;
        }, 500);
      });
    },
    //获取客户信息
    getCust() {
      get(custAPI.getAllCustsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.custList = res.data.data.list;
            return;
          }
          this.$message.error(res.data.code);
        })
        .catch(res => {
          this.$message.error(res.data.code);
        });
    },
    //获取供应商信息
    getSupp() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 刷新
    buttonRefresh() {
      this.$refs.multiTable.resetFields();
      this.getMbins();
    },
    // 更换供应商选择
    replaceSupp(scope) {
      this.sconMtrbIdFlag = scope.scon_mtrb_id;
      this.dialogTableVisible = true;
    },
    // 确认供应商
    suppChangeId(val) {
      post(mbinAPI.editMbinSupp, { scon_mtrb_id: this.sconMtrbIdFlag, supp_id: val })
        .then(res => {
          let mg = res.data.msg;
          if (res.data.code === 0) {
            let tp = 'success';
            this.$message({ message: mg, type: tp });
            this.initData();
          } else {
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
          this.initData();
        });
      this.dialogTableVisible = false;
    },
    //确定生成
    clickConfirm() {
      if (this.multiSelection.length <= 0) {
        return this.$message.warning('至少选择一条数据');
      }
      let temp = this.multiSelection[0].scon_id;
      let flag = false;
      for (let i = 0; i < this.multiSelection.length; i++) {
        if (this.multiSelection[i].scon_id !== temp) {
          flag = true;
        }
      }
      this.multiSelection.forEach(item => {
        if (item.prod_mainsup === '1') {
          temp++;
        }
      });
      if (flag) {
        return this.$message.warning('只能销售合同号相同的数据选择');
      }
      let appliFlag = false;
      this.multiSelection.forEach(item => {
        if (item.scon_mtrb_num === item.scon_mtrb_applied) {
          appliFlag = true;
        }
      });
      if (appliFlag) {
        return this.$message.warning('申购已完成');
      }
      this.$confirm('确定生成?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let temp = {};
          temp.scon_id = this.multiSelection[0].scon_id;
          temp.scon_mtrb_list = this.multiSelection;
          post(modrAPI.addModr, temp)
            .then(res => {
              if (res.data.code === 0) {
                this.$message({
                  type: 'success',
                  message: '生成成功'
                });
                this.jump('/modr_list', { perm_id: 128 });
              } else {
                let mg = res.data.msg;
                let tp = 'error';
                this.$message({ message: mg, type: tp });
              }
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消生成'
          });
        });
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 删除
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        this.mBox();
      }
    },
    //删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delRow();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
          this.$refs.multiTable.clearSelection();
        });
    },
    // 删除
    delRow() {
      const ids = [];
      this.multiSelection.map(item => {
        ids.push(item.scon_mtrb_id);
      });
      let str = ids.join(',');
      post(mbinAPI.destroyMbinsByIds, { scon_mtrb_ids: str })
        .then(res => {
          let mg = res.data.msg;
          if (res.data.code === 0) {
            let tp = 'success';
            this.$message({ message: mg, type: tp });
            if (ids.length === this.tableData.length) {
              if (this.currentPage > 1) {
                this.currentPage = this.currentPage - 1;
                this.$refs.pubPagination.currentPage = this.currentPage;
              }
            }
            this.initData();
          } else {
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
          this.initData();
        });
    },
    //获取录取人信息
    getStffUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffUserList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_dis {
  display: flex;
  justify-content: space-between;
  padding: 0 3px;
  box-sizing: border-box;
}
</style>
